var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "media-banner-with-hubspot-form",
      class:
        ((_obj = {}),
        (_obj["media-banner-with-hubspot-form--vertical"] = _vm.vertical),
        _obj),
    },
    [
      _c("div", { staticClass: "media-banner-with-hubspot-form__container" }, [
        _c(
          "div",
          { staticClass: "media-banner-with-hubspot-form__media" },
          [
            _vm.url
              ? _c("WithAudioContext", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var videoListeners = ref.videoListeners
                          return [
                            _c(
                              "SmartMedia",
                              _vm._g(
                                {
                                  staticClass:
                                    "media-banner-with-hubspot-form__video",
                                  attrs: {
                                    autoplay: "",
                                    paused: false,
                                    controls: "",
                                    src: _vm.url,
                                  },
                                },
                                videoListeners
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3780120148
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "media-banner-with-hubspot-form__form" }, [
          _c("div", { staticClass: "mr-3" }, [
            _c("h1", [_vm._v("KEEP THE MOMENTUM GOING")]),
            _vm.vertical
              ? _c("p", [
                  _vm._v(
                    " We offer In Person, Hybrid and Virtual events for every occasion that connect your teams, boost morale, and build company culture—including our new experience with Jad Abumrad from RadioLab. "
                  ),
                ])
              : _c("p", [
                  _vm._v(
                    " We offer In Person, Hybrid and Virtual events for every occasion that connect your teams,"
                  ),
                  _c("br"),
                  _vm._v(
                    " boost morale, and build company culture—including our new experience with "
                  ),
                  _c("br"),
                  _vm._v("Jad Abumrad from RadioLab. "),
                ]),
          ]),
          _c("div", {
            attrs: { id: "media-banner-with-hubspot-form-hs-target" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }