<template>
  <div
    class="media-banner-with-hubspot-form"
    :class="{ ['media-banner-with-hubspot-form--vertical']: vertical }"
  >
    <div class="media-banner-with-hubspot-form__container">
      <div class="media-banner-with-hubspot-form__media">
        <WithAudioContext #default="{ videoListeners }" v-if="url">
          <SmartMedia
            class="media-banner-with-hubspot-form__video"
            autoplay
            :paused="false"
            controls
            :src="url"
            v-on="videoListeners"
          />
        </WithAudioContext>
      </div>
      <div class="media-banner-with-hubspot-form__form">
        <div class="mr-3">
          <h1>KEEP THE MOMENTUM GOING</h1>
          <p v-if="vertical">
            We offer In Person, Hybrid and Virtual events for every occasion
            that connect your teams, boost morale, and build company
            culture—including our new experience with Jad Abumrad from RadioLab.
          </p>
          <p v-else>
            We offer In Person, Hybrid and Virtual events for every occasion
            that connect your teams,<br />
            boost morale, and build company culture—including our new experience
            with
            <br />Jad Abumrad from RadioLab.
          </p>
        </div>
        <div id="media-banner-with-hubspot-form-hs-target" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import useLobbyAd from "@/use/useLobbyAd"
import WithAudioContext from "@/modules/audio/components/WithAudioContext"
import SmartMedia from "@/modules/smart-media/components/SmartMedia.vue"

function init() {
  window.hbspt.forms.create({
    target: "#media-banner-with-hubspot-form-hs-target",
    region: "na1",
    portalId: "2803895",
    formId: "52155ee5-d001-44ff-b5d8-01e1e65c9c72"
  })
}

export default Vue.extend({
  name: "MediaBannerWithHubSpotForm",
  components: {
    WithAudioContext,
    SmartMedia
  },
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.hsVars = {
      ticks: 1670353010708,
      page_id: 82943833641,
      content_group_id: 0,
      portal_id: 2803895,
      app_hs_base_url: "https://app.hubspot.com",
      cp_hs_base_url: "https://cp.hubspot.com",
      language: "en",
      analytics_page_type: "standard-page",
      analytics_page_id: "82943833641",
      category_id: 1,
      folder_id: 0,
      is_hubspot_user: false
    }

    if (window.hbspt?.forms?.create) {
      init()
    } else {
      const script = document.createElement("script")
      script.setAttribute("src", "https://js.hsforms.net/forms/embed/v2.js")
      document.head.appendChild(script)
      const onScriptLoad = () => {
        script.removeEventListener("load", onScriptLoad)
        init()
      }
      script.addEventListener("load", onScriptLoad)
    }
  },
  setup() {
    const { url } = useLobbyAd()
    return { url }
  }
})
</script>

<style lang="scss">
.media-banner-with-hubspot-form {
  $block: &;

  position: relative;

  &__container {
    background: #2e2990;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__media {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    justify-content: center;
  }

  &__video {
    transform: scaleX(1) !important;
    width: 90%;
    height: auto;
  }

  &__form {
    flex: 1 1 auto;
    color: #fff;
    padding: 16px;
    display: flex;
    justify-content: space-between;

    h1 {
      font-size: 40px;
      line-height: 1em;
    }

    p {
      margin-top: 16px;
    }
  }

  &--vertical {
    #{$block}__video {
      width: 100%;
    }
    #{$block}__form {
      flex: initial;
      flex-direction: column;
      min-height: 50%;
    }
  }
}
</style>
